import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FormContato from "../components/formContato"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const AboutPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Sobre - Denise Barbosa"
        keywords={[
          `Denise Barbosa`,
          `RH AS A SERVICE`,
          `Consultoria RG`,
          `Mentoring`,
        ]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <div className="row">
            <div className="col-8">
              <div
                style={{
                  padding: "1rem 0",
                  textAlign: "left",
                }}
              >
                <p>
                  <b>Prazer, Denise!</b>
                </p>

                <p>
                  Há 20 anos, a Psicologia me escolheu! Enquanto eu buscava ser
                  outra pessoa, o caminho se abriu para ingressar no curso de
                  Psicologia do qual o plano era me transferir no 1º ano de
                  faculdade. Me apaixonei pelo curso, pelas possibilidades e
                  pelo complexo desafio em entender o comportamento humano e
                  ajudar pessoas a progredirem.
                </p>

                <p>
                  Desenvolvi minha carreira como profissional de Recursos
                  Humanos em empresas nacionais e multinacionais de diferentes
                  segmentos, portes e cultura. Ao longo dos anos, tenho
                  trabalhado em posições de liderança generalista, liderando
                  projetos de transformação e fortalecimento da Cultura,
                  Educação&Desenvolvimento, Gestão de Desempenho &Talentos. Atuo
                  na definição e implantação de estratégias para: Atração
                  &Retenção, Marca Empregadora, Experiência do(a)
                  Colaborador(a), Remuneração &Benefícios e toda a gestão da
                  área de Pessoal.
                </p>

                <p>
                  Sou uma entusiasta da tecnologia e do pensamento crítico
                  aplicados em projetos de escalabilidade, gerenciamento de
                  dados, transformação digital e eficiência de serviços,
                  posicionando a gestão de pessoas aos novos comportamentos e
                  necessidades.
                </p>

                <p>
                  Independente por onde passei e projetos realizados, minha
                  atuação sempre foi centrada nas pessoas. Escutar, observar,
                  dialogar, entender, perceber características, desejos
                  coletivos e individuais. Cada pessoa em seu Universo.
                </p>

                <p>
                  A DB_Desenvolvimento Humano&Organizacional é a continuação
                  desta jornada e sua essência é apoiar as pessoas a desempenhar
                  com todo seu potencial e conquistar seus objetivos, promovendo
                  desenvolvimento e aprendizado contínuo. Com uma abordagem
                  360º, a DB oferece soluções personalizadas, considerando
                  aspectos individuais, culturais, éticos, mercadológicos e
                  estratégicos.
                </p>

                <p>
                  <b>Responsabilidade Social</b>
                  <br />
                  Apoiamos a ASID do Brasil nas iniciativas para criar uma
                  sociedade inclusiva onde todos tem a oportunidade em se
                  desenvolver, contribuir e pertencer.
                </p>
                <Img
                  fixed={data.asid.childImageSharp.fixed}
                  imgStyle={{
                    borderRadius: `10%`,
                  }}
                />
              </div>
            </div>
            <div className="col-4">
              <div
                style={{
                  padding: "1rem 0",
                  textAlign: "center",
                }}
              >
                <br />
                <br />
                <Img
                  fluid={data.denisebarbosa.childImageSharp.fluid}
                  className="kg-image"
                ></Img>
              </div>
            </div>
          </div>
          <hr />
          <br />
          <h3 className="post-content-title">Fale Comigo</h3>
          <FormContato />
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    denisebarbosa: file(relativePath: { eq: "sobre-denisebarbosa.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asid: file(relativePath: { eq: "asid.png" }) {
      childImageSharp {
        fixed(width: 200, height: 200) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <AboutPage location={props.location} data={data} {...props} />
    )}
  />
)
